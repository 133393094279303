<template>
  <div class="layout-box" v-loading="loading.state" :element-loading-text="loading.text">
    <vpn-header ref="header"></vpn-header>
    <router-view ref="router-view"></router-view>
    <vpn-footer ref="footer"></vpn-footer>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import VpnHeader from '@/components/layout/header'
import VpnFooter from '@/components/layout/footer'

export default {
  components: { VpnHeader, VpnFooter },
  props: {},
  data () {
    return {
      
    }
  },
  computed: {
    ...mapState({
      loading: state => state.common.loading
    })
  },
  watch: {},
  methods: {
    ...mapMutations(['setLoading']),
  },
  created () {
    this.setLoading({
      state: false,
      text: ''
    })
  },
  mounted () {},
  destroyed () {}
}
</script>
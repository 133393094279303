<template>
  <footer id="footer" class="footer">
    <section class="footer-box">
      <aside class="footer-aside">
        <!-- <div class="footer-logo">
          <a href="/#/">82</a>
        </div> -->
        <div class="footer-tagline">
          <h3>FAST AFFORDABLE RELIABLE</h3>
        </div>
        <ul class="footer-contact">
          <li>
            <strong class="strong">Support:&nbsp;</strong>
            <a href="mailto:support@85proxy.com">support@85proxy.com</a>
          </li>
          <li>
            <strong class="strong">Enquiries:&nbsp;</strong>
            <a :href="CONSTANT.CUSTOMER" target="_blank">Live Chat</a>
          </li>
        </ul>
        <div class="footer-social">
          <p class="footer-social-title">Connect with us</p>
          <ul class="footer-social-icons">
            <!-- <li>
              <a href="#" target="_blank">
                <img src="@/images/icon/discord.svg" alt="" />
              </a>
            </li> -->
            <li>
              <a :href="CONSTANT.CUSTOMER" target="_blank">
                <img src="@/images/icon/telegram2.svg" alt="" />
              </a>
            </li>
            <!-- <li>
              <a href="#" target="_blank">
                <img src="@/images/icon/instagram.svg" alt="" />
              </a>
            </li> -->
          </ul>
        </div>
      </aside>
      <article class="footer-article">
        <div class="footer-menu" v-for="(menu, index) in menus" :key="index">
          <h3>{{menu.title}}</h3>
          <ul class="footer-menu-list">
            <li v-for="(item, key) in menu.childrens" :key="key"><a href="javascript:;" @click="switchMenu(item)">{{item.title}}</a></li>
          </ul>
        </div>
      </article>
    </section>
  </footer>
</template>

<script>
import CONSTANT from '@/config/constant'
import { mapState, mapMutations } from 'vuex'
import { Menu } from '@/mixins/menu'

export default {
  mixins: [ Menu ],
  components: {},
  props: {},
  data () {
    return {
      CONSTANT,
      menus: [{
        title: 'PRODUCTS',
        childrens: [{
          id: 'home',
          title: 'Residential',
          link: '/home?column=Product&category=Residential'
        }, {
          id: 'home',
          title: 'Datacenter',
          link: '/home?column=Product&category=Datacenter'
        }]
      }, {
        title: 'PRICES',
        childrens: [{
          id: 'home',
          title: 'Residential',
          link: '/home?column=Price&category=Residential'
        }, {
          id: 'home',
          title: 'Datacenter',
          link: '/home?column=Price&category=Datacenter'
        }]
      }, {
        title: 'FAQ',
        childrens: [{
          id: 'faq',
          title: 'Faq',
          link: '/faq'
        }]
      }]
    }
  },
  computed: {},
  watch: {},
  methods: {
    ...mapMutations(['setCommonState']),
    
  },
  created () {},
  mounted () {},
  destroyed () {}
}
</script>
<template>
  <header id="header" class="header">
    <div class="header-box">
      <ul class="header-menu">
        <li v-for="(item) in menus" :key="item.id" :class="item.id === curMenuId ? 'active' : ''">
          <el-popover
            v-if="item.childrens.length > 0"
            width="440"
            trigger="hover"
            placement="bottom-start">
            <a slot="reference" class="header-menu-link" href="javascript:;"><i :class="item.icon"></i>{{item.title}}</a>
            <section class="header-menu-box">
              <header class="header-smenu-head">
                <img src="@/images/icon/product-icon.svg" alt="" />
                <span class="product-span">{{item.bigTitle}}</span>
              </header>
              <article class="header-smenu-body">
                <div class="header-smenu-item" v-for="(smenu, key) in item.childrens" :key="key">
                  <a href="javascript:;" @click="switchMenu(smenu)">
                    <img class="icon" :src="smenu.icon" />
                    <span class="header-smenu-box">
                      <span class="header-smenu-dt">{{smenu.dt}}</span>
                      <span class="header-smenu-dd">{{smenu.dd}}</span>
                    </span>
                    <span>
                      <span class="header-smenu-from">FROM</span>
                      <span class="header-smenu-zdj">${{smenu.price}}</span>
                    </span>
                  </a>
                </div>
              </article>
            </section>
          </el-popover>
          <a v-else class="header-menu-link" href="javascript:;" @click="switchMenu(item)"><i :class="item.icon"></i>{{item.title}}</a>
        </li>
      </ul>
      <ul class="header-top-box">
        <template v-if="userInfo">
        <li>
          <a href="javascript:;" @click="logout"><i class="el-icon-upload2"></i>Logout</a>
        </li>
        <li>
          <a href="javascript:;" @click="jumpPage('/mine/info')"><i class="el-icon-s-cooperation"></i>My Profile</a>
        </li>
<!--        <li>-->
<!--          <a href="javascript:;" @click="jumpPage('/mine/bill')"><i class="el-icon-s-order"></i>My Bill</a>-->
<!--        </li>-->
        </template>
        <li v-else>
          <a href="javascript:;" @click="jumpPage('/login')"><i class="el-icon-s-opportunity"></i>Login</a>&nbsp;&nbsp;
          <a href="javascript:;" @click="jumpPage('/register')"><i class="el-icon-s-check"></i>Sign Up</a>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { Menu } from '@/mixins/menu'

export default {
  mixins: [ Menu ],
  components: {},
  props: {},
  data () {
    return {
      menus: [{
        id: 'home',
        title: 'Home',
        icon: 'el-icon-s-home',
        link: '/home',
        childrens: []
      }, {
        title: 'Products',
        bigTitle: 'PRODUCTS',
        icon: 'el-icon-s-flag',
        link: '',
        childrens: [{
          id: 'home',
          title: 'Residential',
          dt: 'Residential Proxies',
          dd: 'Human-like scraping with no ratelimits',
          price: 2,
          icon: require('@/images/icon/house.svg'),
          link: '/home?column=Product&category=Residential'
        }, {
          id: 'home',
          title: 'Datacenter',
          dt: 'Datacenter Proxies',
          dd: 'Cost-effective web scraping at scale',
          price: 0.35,
          icon: require('@/images/icon/driver.svg'),
          link: '/home?column=Product&category=Datacenter'
        }]
      }, {
        title: 'Prices',
        bigTitle: 'PRICES',
        icon: 'el-icon-s-finance',
        link: '',
        childrens: [{
          id: 'home',
          title: 'Residential',
          dt: 'Residential Proxies',
          dd: 'Human-like scraping with no ratelimits',
          price: 2,
          icon: require('@/images/icon/house.svg'),
          link: '/home?column=Price&category=Residential'
        }, {
          id: 'home',
          title: 'Datacenter',
          dt: 'Datacenter Proxies',
          dd: 'Cost-effective web scraping at scale',
          price: 0.35,
          icon: require('@/images/icon/driver.svg'),
          link: '/home?column=Price&category=Datacenter'
        }]
      }, {
        id: 'faq',
        title: 'Faq',
        icon: 'el-icon-question',
        link: '/faq',
        childrens: []
      }]
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.common.userInfo,
      curMenuId: state => state.common.curMenuId
    })
  },
  watch: {},
  methods: {
    ...mapMutations(['setUserInfo', 'setCommonState']),
    logout() {
      this.$confirm('Confirm logging out?', 'Prompt', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.setUserInfo(null)
        clearVuexAlong(false, true)
        this.$router.push('/login')
      }).catch(() => {
      });
    },
    jumpPage(url) {
      // console.log(url)
      this.setCommonState({
        label: 'curMenuId',
        value: ''
      })
      this.$router.push(url)
    }
  },
  created () {},
  mounted () {},
  destroyed () {}
}
</script>
export const Menu = {
  data() {
    return {
      
    }
  },
  methods: {
    switchMenu(item) {
      // console.log(item, this.$router)
      if (item.id == 'home') {
        // console.log(item.link, this.$router.history.current.fullPath)
        if (item.link === this.$router.history.current.fullPath) {
          window.location.reload()
        } else {
          this.setCommonState({
            label: 'curMenuId',
            value: 'home'
          })
          this.$router.push(item.link).then(() => {
            window.location.reload()
          })
        }
      } else {
        if (this.$route.path !== item.link) {
          // console.log(item)
          this.setCommonState({
            label: 'curMenuId',
            value: item.id
          })
          this.$router.push(item.link)
        }
      }
    }
  }
}